import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  DateQueryType,
  DateRange,
  DialogComponent,
  DialogTypes,
  DynamicPlaceholderDirective,
  FAwesomeModule,
  IError,
  IPresetQuery,
  PillType,
  QueryFilters,
  Sizes,
  TableColumn,
  TagCategory,
} from '@intorqa-ui/core';
import { TimelineFeedComponent } from '@portal/boards/components/timeline-feed/timeline-feed.component';
import { ChartTypes } from '@portal/boards/const/widget.const';
import { TagAnalysis } from '@portal/boards/models/tag-analysis';
import { TagComparison } from '@portal/boards/models/tag-comparison';
import { TimeSeries } from '@portal/boards/models/time-series';
import { WidgetService } from '@portal/boards/services/widget.service';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';
import {
  AnalysisTypes,
  ChartType,
  WidgetActions,
} from '@portal/shared/enums/widget.enum';
import { IData } from '@portal/shared/interfaces/document.interface';
import {
  IDisplayType,
  IWidgetData,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { Timeline } from '@portal/shared/models/timeline';
import { TagService } from '@portal/shared/pipes/tag.service';
import { UserService } from '@portal/shared/services/user.service';
import { WidgetSettingsFactory } from '@portal/widget-settings/widget-settings.factory';
import moment from 'moment';
import { MapLabelPipe, SelectMetricsLabelPipe } from './chart-wizard.pipe';
import { BoardService } from '@portal/boards/services/board.service';
import { Subscription } from 'rxjs';
import { WidgetSettingsService } from '@portal/widget-settings/services/widget-settings.service';

@Component({
  selector: 'itq-chart-wizard',
  templateUrl: './chart-wizard.component.html',
  styleUrls: ['./chart-wizard.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    ChartComponent,
    MatTooltipModule,
    MapLabelPipe,
    TimelineFeedComponent,
    CommonModule,
    SelectMetricsLabelPipe,
  ],
})
export class ChartWizardComponent implements OnInit {
  @Input() widget: TagAnalysis | TimeSeries | TagComparison | Timeline;
  @Input() action: WidgetActions;

  @Output() close = new EventEmitter<void>();

  public form: FormGroup;
  private componentRef: ComponentRef<any>;
  public tableColumns: Array<TableColumn>;
  public data: IWidgetData;
  public showLoader = false;
  public chartTypesDataSource: Array<IDisplayType>;
  public timelineData: IData;
  private tagAnalysisWidget: TagAnalysis;
  private subscription = new Subscription();
  public initialState = new QueryFilters(
    100,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    undefined,
  );

  readonly ChartType = ChartType;
  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly AnalysisTypes = AnalysisTypes;
  readonly WidgetActions = WidgetActions;

  @ViewChild(DynamicPlaceholderDirective, { static: true })
  placeholder: DynamicPlaceholderDirective;

  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    readonly userService: UserService,
    readonly widgetService: WidgetService,
    readonly cdr: ChangeDetectorRef,
    readonly customOverlayRef: CustomOverlayRef,
    private snackBar: MatSnackBar,
    readonly tagService: TagService,
    readonly boardService: BoardService,
    readonly widgetSettingsService: WidgetSettingsService,
    readonly customOverlayService: CustomOverlayService,
  ) {}

  ngOnInit(): void {
    this.initialState.where = this.boardService.board.filter?.date;
    this.boardService.board?.filter?.date;
    this.chartTypesDataSource = ChartTypes[this.widget.type];
    this.createForm();
    this.createComponent();
    this.onDataBound(this.initialState);
    this.bindOnDataBoundSubscription();
    this.bindLoaderSubscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private bindLoaderSubscription(): void {
    this.subscription.add(
      this.widgetSettingsService.loader$.subscribe((response: boolean) => {
        this.showLoader = response;
      }),
    );
  }

  private bindOnDataBoundSubscription(): void {
    this.subscription.add(
      this.widgetService.reloadWidget$.subscribe(
        (params: {
          widget: TagAnalysis | TagComparison | TimeSeries | Timeline;
          dates: IPresetQuery;
        }) => {
          this.initialState.where = params.dates;
          if (
            this.widget.type === AnalysisTypes.TIMELINE &&
            this.widget.chartType !== ChartType.TIMELINE
          ) {
            this.tagAnalysisWidget.dataSource = (
              this.widget as Timeline
            ).dataSource;
            this.getChartData(this.tagAnalysisWidget);
          } else {
            this.widget = params.widget;
            this.onDataBound(this.initialState);
          }
        },
      ),
    );
  }

  private createForm(): void {
    this.form = new FormGroup({
      name: new FormControl(
        {
          value: this.widget.name,
          disabled: this.widget.type === AnalysisTypes.TIMELINE,
        },
        [Validators.required],
      ),
      description: new FormControl({
        value: this.widget.description,
        disabled: this.widget.type === AnalysisTypes.TIMELINE,
      }),
      chartType: new FormControl(this.chartTypesDataSource[0].id, [
        Validators.required,
      ]),
    });
  }

  private createComponent(): void {
    const viewContainerRef = this.placeholder.viewContainerRef;
    viewContainerRef.clear();
    const dynamicComponentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        WidgetSettingsFactory.getComponent(this.widget.type),
      );

    this.componentRef = viewContainerRef.createComponent(
      dynamicComponentFactory,
    );
    this.componentRef.instance.dates = this.initialState.where;
    this.componentRef.instance.widget = this.widget;
    this.componentRef.instance.form = this.form;
  }

  public onDataBound(params: QueryFilters): void {
    if (this.widget.hasOptions()) {
      this.showLoader = true;
      this.initialState = params;
      if (this.widget.chartType === ChartType.TIMELINE) {
        this.getTimelineData();
      } else {
        this.getChartData(
          this.widget as TagAnalysis | TagComparison | TimeSeries,
        );
      }
    }
  }

  private getTimelineData(): void {
    const queryModel = new Query([
      new QueryRule(DTOQueryFieldType.tag, DTOQueryConditionOperator.in, [
        (this.widget as Timeline).dataSource[0],
      ]),
    ]);
    this.tagService
      .execute(
        this.initialState,
        queryModel,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .then((response: IData) => {
        this.timelineData = response;
        this.showLoader = false;
      });
  }

  private getChartData(widget: TagAnalysis | TagComparison | TimeSeries): void {
    if (this.widget.hasOptions()) {
      this.showLoader = true;

      this.widgetService
        .getData(
          {
            widget: widget,
            filters: new Query().modelToDTO(),
          },
          {
            where: this.initialState?.where,
          },
        )
        .then((response: IWidgetData) => {
          this.tableColumns = widget.getTableColumns(this.countTemplate);
          this.data = response;
          this.cdr.detectChanges();
          this.widgetService.reloadChart$.next(this.widget);
          this.showLoader = false;
        })
        .catch((error: IError) => {
          this.showLoader = false;
          this.customOverlayService.openCustom(
            {
              title: error.title,
              message: error.description,
              icon: ['far', 'exclamation-circle'],
              size: '4x',
              dialog: {
                type: DialogTypes.ALERT,
              },
            },
            DialogComponent,
          );
        });
    }
  }

  public onChangeChartType(params: IDisplayType): void {
    let widget: TagAnalysis | TagComparison | TimeSeries | Timeline;
    if (this.widget.type === AnalysisTypes.TIMELINE) {
      widget = this.widget as Timeline;
      this.widget.chartType = params.type;
      if (this.widget.chartType !== ChartType.TIMELINE) {
        this.tagAnalysisWidget = new TagAnalysis(
          widget.widgetId,
          widget.username,
          AnalysisTypes.TAG_ANALYSIS,
          widget.name,
          widget.description,
          widget.width,
          widget.height,
          widget.x,
          widget.y,
          params.options,
          widget.dataSource,
          [params.id as TagCategory],
          1000,
          ChartType.TABLE,
          widget.ecosystemId,
          widget.updatedDate,
        );
        if (this.widget instanceof Timeline) {
          this.widget.dataPoints = [params.id as TagCategory];
        }
        this.getChartData(this.tagAnalysisWidget);
      } else {
        if (this.widget instanceof Timeline) {
          this.widget.dataPoints = undefined;
        }
        this.onDataBound(this.initialState);
      }
    } else {
      widget = this.widget as TagAnalysis | TagComparison | TimeSeries;
      widget.options = params.options;
      widget.chartType = params.type;
      this.cdr.detectChanges();
      this.widgetService.reloadChart$.next(this.widget);
    }
  }

  public onPrev(): void {
    this.close.emit();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched(); // Mark all fields as touched to trigger validation messages
    if (this.form.valid) {
      this.showLoader = true;
      if (this.widget.widgetId) {
        this.onEdit();
      } else {
        this.onSave();
      }
    }
  }

  private onEdit(): void {
    this.widgetService
      .updateWidget(this.widget)
      .subscribe(
        (response: TimeSeries | TagComparison | TagAnalysis | Timeline) => {
          this.snackBar.open('Your widget has been updated!', 'Close', {
            horizontalPosition: 'right',
            duration: 5000,
            verticalPosition: 'top',
          });
          this.customOverlayRef.close();
        },
      );
  }

  private onSave(): void {
    this.widgetService
      .createWidget(this.widget)
      .subscribe(
        (response: TimeSeries | TagComparison | TagAnalysis | Timeline) => {
          this.customOverlayRef.close({ refresh: true, widget: response });
        },
      );
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
